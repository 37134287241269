<template>
  <div class="">
    <AddLink />
  </div>
  <div class="container-small">
    <ListActions />
    <LinkList />
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
import LinkList from '@/components/LinkList.vue'
import AddLink from '@/components/AddLink.vue'
import ListActions from "../components/ListActions";

export default {
  name: 'Home',
  components: {
    ListActions,
    LinkList,
    AddLink
  },
}
</script>

<style  lang="scss" scoped>
@import "../scss/variables";

</style>
