<template>
  <header id="app" class="container-full">
    <nav>
      <ul>
        <li class="logo">
          <router-link to="/">
            <img src="../assets/throw_li_logo.svg" alt="throw.li">
          </router-link>
        </li>
        <li v-for="(item, index) in navItems" :key="index">
          <router-link :to="item.to" exact-active-class="active" :class="item.cssClass">
            {{ item.label }}
          </router-link>
        </li>
      </ul>
    </nav>
  </header>
</template>

<script>
export default {
  name: "Header",
  methods: {

  },
  data() {
    return {
      activeNavItemTo: '/',
      navItems: [
        {
          label: 'Profile',
          to: '/profile',
        },
        {
          label: 'Changelog',
          to: '/changelog',
        },
        {
          label: 'Logout',
          to: '/logout',
          cssClass: 'logout',
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../scss/variables";

header {
  color: $color-white;
  background-color: $color-dark;
  font-size: $font-size-base;
}
ul, li {
  list-style: none;
  margin: 0;
  padding: 0;
  display: inline;
}
.logo {
  padding: 0 $padding-base 0 0;
  margin-right: $margin-base;
}
a {
  color: $color-header-link-default;
  text-decoration: none;
  padding: $padding-base $padding-base;
  &:hover:not(.logo), &.active {
    background-color: $color-header-link-hover;
  }
}
a.logout {
  @extend .bg-dark;
  &:hover {
    background-color: $color-error !important;
  }
}
</style>
