<template>
  <div class="list-actions">
    <button type="button" class="btn btn-text-danger" @click="removeAllLinks">Delete all</button>
  </div>
</template>

<script>
export default {
  name: "ListActions",
  methods: {
    removeAllLinks() {
      this.emitter.emit('remove-all-links');
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../scss/variables";

.list-actions {
  margin: $margin-s 0 $margin-base 0;
  text-align: right;
}
.btn {
  display: inline-block;
  font-weight: 700;
  line-height: 1.5;
  //color: #212529;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  //background-color: transparent;
  //border: 1px solid transparent;
  padding: .25rem .5rem;
  font-size: .875rem;
  border-radius: 0.25rem;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;

  // Default button style
  color: $color-white;
  background-color: $color-primary;
  border: .2rem solid $color-primary;

  &.btn-primary {
    color: $color-white;
    background-color: $color-primary;
    border-color: $color-primary;
  }
  &.btn-success {}
  &.btn-warning {}
  &.btn-danger {}
  &.btn-text-primary {}
  &.btn-text-success {}
  &.btn-text-warning {}
  &.btn-text-danger {
    color: $color-error;
    background-color: transparent;
    border-color: transparent;
  }
}
</style>
