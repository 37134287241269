<template>
  <div>
    <div v-for="(link, index) in linkStore.urls" :key="index">
      <LinkItem :url="link.url"></LinkItem>
    </div>
    <div v-if="linkStore.urls.length <= 0">
      No data found
    </div>
  </div>
</template>

<script>
import { useLinkStore } from "../stores/links";
import LinkItem from "./LinkItem";

export default {
  name: "LinkList",
  setup() {
    const linkStore = useLinkStore()

    return {
      linkStore,
    }
  },
  mounted() {
    this.emitter.on('remove-link', (name) => {
      this.linkStore.removeLink(name);
    });
    this.emitter.on('remove-all-links', () => {
      this.linkStore.removeAllLinks();
    });
  },
  components: {
    LinkItem
  },
  data() {
    return {}
  },
}
</script>

<style scoped>

</style>
