<template>
<!--  tag--type-link -->
  <span class="tag" :class="getCssClassDataType()">{{ getDataType }}</span>
</template>

<script>
export default {
  name: "TypeTag",
  props: {
    data: String
  },
  methods: {
    setDataType(type) {
      this.type = type;
    },
    getCssClassDataType() {
      return `tag--type-${this.type.toLowerCase()}`;
    }
  },
  computed: {
    getDataType() {
      const isValidLink = /^(http|https):\/\/[^ "]+$/.test(this.data);
      this.setDataType(isValidLink ? 'Link' : 'Undefined');

      return this.type;
    }
  },
  data() {
    return {
      type: 'Undefined'
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../scss/variables";

.tag {
  text-transform: uppercase;
  font-size: .75em;
  font-weight: bold;
  color: $color-white;
  background-color: $color-tag-default;
  padding: .35em .65em;
  border-radius: 50rem !important;
  display: inline-block;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  line-height: 1;
  &--type-link {
    color: $color-white;
    background-color: $color-tag-link;
  }
}
</style>
