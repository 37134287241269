<template>
  <div class="link-item">
    {{ url }}
    <TypeTag :data="url" />
    <button
      @click="removeLink(url)"
      type="button">X</button>
  </div>
</template>

<script>
import TypeTag from "./TypeTag";
export default {
  name: "LinkItem",
  components: {TypeTag},
  methods: {
    removeLink(url) {
      this.emitter.emit('remove-link', url);
    }
  },
  props: {
    url: String,
    // id: Number
    // createDate: String,
    // ...
  }
}
</script>

<style lang="scss" scoped>
@import "../scss/variables";

.link-item {
  box-shadow: $box-shadow-default;
  padding: $padding-base $padding-base;
  margin-bottom: $margin-base;
  border-radius: $border-radius-default;

  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  align-content: flex-end;
  flex-basis: 33.333333%
}
</style>
