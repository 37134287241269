<template>
  <div class="link-form container-full content-center">
    <form @submit.prevent="save" class="input-group">
      <input type="text" v-model="linkUrl" class="input" />
      <button class="input-group-icon" @click="save">
        💾
      </button>
    </form>
  </div>
</template>

<script>
import { defineComponent, ref } from 'vue'
import { useLinkStore } from '../stores/links'

export default defineComponent(
    {
      name: "AddLink",
      setup() {
        const linkStore = useLinkStore()
        const linkUrl = ref('')

        function addLink() {
          if (!linkUrl.value) {
            return
          }

          linkStore.addLink(linkUrl.value);
        }

        // @ts-ignore
        window.stores = { linkStore }

        return {
          linkStore,
          linkUrl,
          addLink
        }
      },
      data() {
        return {
        }
      },
      methods: {
        save() {
          this.addLink();
          this.linkUrl = '';
          console.log(`Save string done to store with items ...`);
          console.log(this.linkStore);
        },
      }
    }
)

</script>

<style lang="scss" scoped>
@import "../scss/variables";
.link-form {
  background-color: $color-highlight;
  padding: $padding-extended $padding-base !important;
}
.input-group {
  width: 80%;
  display: inline-flex;
  background-color: $color-white;
  align-items: center;
  border-radius: $border-radius-default;
  overflow: hidden;
  font-size: 2rem;
  box-shadow: $box-shadow-default;
}
.input-group input {
  border: 0;
  padding: 1rem;
}
.input-group input:focus {
  outline: 0;
}
.input-group-icon {
  padding: 0.5rem;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  border: 0;
  background-color: inherit;
  cursor: pointer;
}
input {
  width: inherit;
}
input, button {
  font-size: 2rem;
}
</style>
