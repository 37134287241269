// @ts-check
import {
    defineStore,
    // acceptHMRUpdate
} from 'pinia'
import { useStorage } from '@vueuse/core'

export const useLinkStore = defineStore({
    id: 'links',
    state: () => ({
        linkItems: useStorage('linkItems', []),
    }),
    getters: {
        getAllLink() {
            return this.linkItems
        },
        urls: (state) =>
            state.linkItems.reduce((items, item) => {
                const existingItem = items.find((it) => it.url === item)

                if (!existingItem) {
                    items.push({ url: item, /* amount: 1 */ })
                }
                // else {
                //     existingItem.amount++
                // }

                return items
            }, []),
    },
    actions: {
        addLink(linkUrl) {
            if (!this.linkItems.includes(linkUrl)) {
                this.linkItems.push(linkUrl)
            } else {
                console.warn(`Link (${linkUrl}) already in store`)
            }
        },

        /**
         * Remove item from the cart
         * @param {string} name
         */
        removeLink(name) {
            console.log('>> removeLink: ', name);
            const i = this.linkItems.lastIndexOf(name)
            console.log('>> removeLink at: ', i);
            if (i > -1) this.linkItems.splice(i, 1)
        },
        removeAllLinks() {
            console.warn('Clear all data from store')
            this.linkItems = [];
        }
    },
})

// if (import.meta.hot) {
//     import.meta.hot.accept(acceptHMRUpdate(useLinkStore, import.meta.hot))
// }
