<template>
  <div class="container content-page">
    <h1 style="display: none">Changelog</h1>
    <Changelog />
  </div>
</template>

<script>
import Changelog from '../components/Changelog';

export default {
  components: {
    Changelog
  }
}
</script>

<style lang="scss" scoped>
@import "../scss/variables";

</style>
