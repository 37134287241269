import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { createPinia } from 'pinia'
import mitt from 'mitt';
import axios from 'axios'
import VueAxios from 'vue-axios'

const emitter = mitt(); // Event emitter
const piniaStore = createPinia();

const app = createApp(App);

app.config.globalProperties.emitter = emitter
app.use(piniaStore)
app.use(router)
app.use(VueAxios, axios)
app.mount('#app')
