<template>
  <div class="container-small">

    <div class="log-item" v-for="(log, index) in gitlog" :key="index">
      <p class="log-date">
        {{ log.commiter.date }}
      </p>
      <p class="log-subject">
        {{ log.subject }}
      </p>
    </div>

  </div>
</template>

<script>

import gitlog from '../../gitlog.json';

export default {
  name: "Changelog",
  setup() {
    return {
      gitlog
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../scss/variables";

.log-date {
  font-weight: bold;
}
.log-item {
  box-shadow: $box-shadow-default;
  padding: $padding-base $padding-base;
  margin-bottom: $margin-base;
  border-radius: $border-radius-default;
}
</style>
